// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.produto-modal{

}

.detalhes-produto{
    max-height: 600px; 
    overflow: auto;
    align-content: flex-start;
    
}

.img-produto-modal{
    object-fit: contain;
    width: 100%;
    height: 150px;
}

.produto-modal .promocao{
    color: #23AD6B;
}

.produto-modal .preco-antigo{
    text-decoration: line-through;
    color: #999
}

.button-qtd{
    font-size: 18pt;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/produto/modal/style.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,yBAAyB;;AAE7B;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B;AACJ;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".produto-modal{\r\n\r\n}\r\n\r\n.detalhes-produto{\r\n    max-height: 600px; \r\n    overflow: auto;\r\n    align-content: flex-start;\r\n    \r\n}\r\n\r\n.img-produto-modal{\r\n    object-fit: contain;\r\n    width: 100%;\r\n    height: 150px;\r\n}\r\n\r\n.produto-modal .promocao{\r\n    color: #23AD6B;\r\n}\r\n\r\n.produto-modal .preco-antigo{\r\n    text-decoration: line-through;\r\n    color: #999\r\n}\r\n\r\n.button-qtd{\r\n    font-size: 18pt;\r\n    vertical-align: middle;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
