// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    overflow-x: hidden;
    font-family: 'Poppins';
}

.mt-page{
    margin-top: 90px;
}

.react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.react-modal-content{
    width: 100%;
    max-width: 600px;
    background: #fff;
    padding: 1rem;
    position: relative;
    border-radius: 0.24rem;
    margin-left: 15px;
    margin-right: 15px;
}

.react-modal-close{
    position: absolute;
    right: -20px;
    top: -15px;
    border: 0;
    background: transparent;    
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;;IAEP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,SAAS;IACT,uBAAuB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');\r\n\r\nbody{\r\n    overflow-x: hidden;\r\n    font-family: 'Poppins';\r\n}\r\n\r\n.mt-page{\r\n    margin-top: 90px;\r\n}\r\n\r\n.react-modal-overlay {\r\n    background: rgba(0, 0, 0, 0.5);\r\n    position: fixed;\r\n    top: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    left: 0;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    z-index: 1050;\r\n}\r\n\r\n.react-modal-content{\r\n    width: 100%;\r\n    max-width: 600px;\r\n    background: #fff;\r\n    padding: 1rem;\r\n    position: relative;\r\n    border-radius: 0.24rem;\r\n    margin-left: 15px;\r\n    margin-right: 15px;\r\n}\r\n\r\n.react-modal-close{\r\n    position: absolute;\r\n    right: -20px;\r\n    top: -15px;\r\n    border: 0;\r\n    background: transparent;    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
