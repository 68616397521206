// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-login{
    width: 330px;
    padding: 15px;
}

.form-login input{
    margin-bottom: 10px;    
}

.background-login{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".form-login{\r\n    width: 330px;\r\n    padding: 15px;\r\n}\r\n\r\n.form-login input{\r\n    margin-bottom: 10px;    \r\n}\r\n\r\n.background-login{\r\n    object-fit: cover;\r\n    width: 100%;\r\n    height: 100vh;\r\n    object-position: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
