// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardapio .classificacao{
    font-size: 14px;
    color: rgb(51, 51, 51);
}

.img-estab-cardapio{
    object-fit: cover;
    width: 100%;
    height: 200px;
}

.favorito{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/cardapio/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".cardapio .classificacao{\r\n    font-size: 14px;\r\n    color: rgb(51, 51, 51);\r\n}\r\n\r\n.img-estab-cardapio{\r\n    object-fit: cover;\r\n    width: 100%;\r\n    height: 200px;\r\n}\r\n\r\n.favorito{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
