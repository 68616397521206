// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.endereco{

}

.endereco select{
    width: 100%;
    border: 0px;
    background-color: #fff;
    height: 30px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/endereco/modal/style.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".endereco{\r\n\r\n}\r\n\r\n.endereco select{\r\n    width: 100%;\r\n    border: 0px;\r\n    background-color: #fff;\r\n    height: 30px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
