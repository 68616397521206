// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categoria a{
    color: rgb(56, 56, 56);
    text-decoration: none;
}

.categoria a:hover{
    color: rgb(200, 50, 50);    
}

.categoria span{
    font-size: 13px;
}

.img-categoria{
    max-width: 55px;
}`, "",{"version":3,"sources":["webpack://./src/components/categoria/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".categoria a{\r\n    color: rgb(56, 56, 56);\r\n    text-decoration: none;\r\n}\r\n\r\n.categoria a:hover{\r\n    color: rgb(200, 50, 50);    \r\n}\r\n\r\n.categoria span{\r\n    font-size: 13px;\r\n}\r\n\r\n.img-categoria{\r\n    max-width: 55px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
