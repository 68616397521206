// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-estabelecimento {
    max-width: 100%;
}

.estabelecimento {
    border-radius: 8px;
}

.estabelecimento a{
    color: rgb(53, 53, 53);
    text-decoration: none;
}

.estabelecimento:hover{
    background-color: rgb(238, 238, 238);
}

.avaliacao span{
    color: rgb(53, 53, 53);
    font-size: 14px;
    padding: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/estabelecimento/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".img-estabelecimento {\r\n    max-width: 100%;\r\n}\r\n\r\n.estabelecimento {\r\n    border-radius: 8px;\r\n}\r\n\r\n.estabelecimento a{\r\n    color: rgb(53, 53, 53);\r\n    text-decoration: none;\r\n}\r\n\r\n.estabelecimento:hover{\r\n    background-color: rgb(238, 238, 238);\r\n}\r\n\r\n.avaliacao span{\r\n    color: rgb(53, 53, 53);\r\n    font-size: 14px;\r\n    padding: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
