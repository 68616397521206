// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.produto-lista .preco-antigo{
    text-decoration: line-through;
    color: #999;
}

.produto-lista a{
    text-decoration: none;
    color: rgb(51, 51, 51);
    cursor: pointer;
}

.produto-lista:hover{
    background-color: rgb(238, 238, 238);
}
`, "",{"version":3,"sources":["webpack://./src/components/produto/lista/style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".produto-lista .preco-antigo{\r\n    text-decoration: line-through;\r\n    color: #999;\r\n}\r\n\r\n.produto-lista a{\r\n    text-decoration: none;\r\n    color: rgb(51, 51, 51);\r\n    cursor: pointer;\r\n}\r\n\r\n.produto-lista:hover{\r\n    background-color: rgb(238, 238, 238);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
