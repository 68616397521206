// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar h5{
    height: 30px;
    margin-bottom: 0px;
}

.sidebar .footer{
    height: 265px;
}

.sidebar .produtos{
    height: calc(100% - 295px);
    overflow: auto;
    align-content: flex-start;
}

.btn-pedido{
    height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".sidebar h5{\r\n    height: 30px;\r\n    margin-bottom: 0px;\r\n}\r\n\r\n.sidebar .footer{\r\n    height: 265px;\r\n}\r\n\r\n.sidebar .produtos{\r\n    height: calc(100% - 295px);\r\n    overflow: auto;\r\n    align-content: flex-start;\r\n}\r\n\r\n.btn-pedido{\r\n    height: 80px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
