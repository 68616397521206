// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pedido{
    border-radius: 6px;
}
.pedido a{
    color: rgb(53, 53, 53);   
    text-decoration: none;     
}

.pedido:hover{
    background-color: rgb(238, 238, 238);
}

.pedido span{
    color: rgb(51, 51, 51);
    font-size: 14px;
    padding: 4px;    
}

.img-pedido {
    width: 80px;
    border-radius: 8px;
}

.pedido-avaliar{
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/pedido/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".pedido{\r\n    border-radius: 6px;\r\n}\r\n.pedido a{\r\n    color: rgb(53, 53, 53);   \r\n    text-decoration: none;     \r\n}\r\n\r\n.pedido:hover{\r\n    background-color: rgb(238, 238, 238);\r\n}\r\n\r\n.pedido span{\r\n    color: rgb(51, 51, 51);\r\n    font-size: 14px;\r\n    padding: 4px;    \r\n}\r\n\r\n.img-pedido {\r\n    width: 80px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.pedido-avaliar{\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
