// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-cadastro{
    width: 90%;
    padding: 15px;
}

.form-cadastro input{
    margin-bottom: 10px;    
}

.form-cadastro select{
    width: 100%;
    border: 0px;
    background-color: #fff;
    height: 43px;
    cursor: pointer;
}

.background-cadastro{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/cadastro/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".form-cadastro{\r\n    width: 90%;\r\n    padding: 15px;\r\n}\r\n\r\n.form-cadastro input{\r\n    margin-bottom: 10px;    \r\n}\r\n\r\n.form-cadastro select{\r\n    width: 100%;\r\n    border: 0px;\r\n    background-color: #fff;\r\n    height: 43px;\r\n    cursor: pointer;\r\n}\r\n\r\n.background-cadastro{\r\n    object-fit: cover;\r\n    width: 100%;\r\n    height: 100vh;\r\n    object-position: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
